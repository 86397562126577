<!--
 * @Author: mulingyuer
 * @Date: 2021-09-08 16:58:08
 * @LastEditTime: 2021-09-09 14:57:00
 * @LastEditors: mulingyuer
 * @Description: 章节课程
 * @FilePath: \saas-admin-vue\src\modules\online-classroom\components\course-manage\add-edit\ChapterCourse.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="modular chapter-course">
    <h2 class="modular-title">章节课程</h2>
    <div class="modular-content">
      <el-form-item label="课程内容：" prop="chapters">
        <el-checkbox-group v-show="false" v-model="form.chapters"></el-checkbox-group>
        <el-button @click="onAddChapter">添加章节</el-button>
        <div class="info">
          <i class="el-icon-info">文档类完成条件为浏览位置/文档详情总长度，音视频完成条件为最大的播放时间点/总时长，最大播放时间点包括拖拽操作</i>
        </div>
        <div class="chapter-list" :class="{draggable:chaptersDraggable}">
          <Draggable v-model="form.chapters" handle=".chapter-item-sort" animation="250"
            :force-fallback="true" :scroll-sensitivity="100" @start="chaptersDraggable=true"
            @end="chaptersDraggable=false" fallbackClass="mirror-item">
            <div v-for="(item,index) in form.chapters" :key="item.uuid || item.id" class="chapter-item">
              <div class="chapter-item-head">
                <div class="left">
                  <PopoverInputEdit v-model="item.name" nowrap>
                    <template slot-scope="{value}">
                      <h2 class="title">{{value|placeholder}}</h2>
                    </template>
                  </PopoverInputEdit>
                </div>
                <div class="right">
                  <el-button size="small" @click="onAddMaterial(index)">新增素材</el-button>
                  <el-button size="small" @click="onDeleteChapter(index)">删除</el-button>
                  <el-button size="small" icon="el-icon-rank" class="sort chapter-item-sort"></el-button>
                </div>
              </div>
              <div class="chapter-item-body">
                <Draggable v-model="item.reses" handle=".material-item-sort" animation="250"
                  :force-fallback="true" :scroll-sensitivity="100" group="material">
                  <div v-for="(child,cndex) in item.reses" :key="child.uuid || child.id"
                    class="material-item">
                    <div class="serial">{{cndex+1}}</div>
                    <TypeIcon type="image" />
                    <h2 class="title">{{child.title|placeholder}}</h2>
                    <el-button type="text" icon="el-icon-sort" class="sort material-item-sort"></el-button>
                    <el-button class="delete" type="danger" size="mini" icon="el-icon-close" plain circle
                      @click="onDeleteMaterial(index, cndex)">
                    </el-button>
                  </div>
                </Draggable>
              </div>
            </div>
          </Draggable>
        </div>
      </el-form-item>
    </div>
  </div>
</template>

<script>
import TypeIcon from "../../default/TypeIcon.vue";
import PopoverInputEdit from "@/base/components/Popover/PopoverInputEdit";
import Draggable from "vuedraggable";
import { uuid } from "@/base/utils/tool";
export default {
  props: {
    //表单数据
    form: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      chaptersDraggable: false, //章节是否开始拖拽
    };
  },
  methods: {
    //添加章节
    onAddChapter() {
      this.form.chapters.push({
        id: "",
        name: "课程标题",
        uuid: uuid(),
        reses: [],
      });
    },
    //删除章节
    onDeleteChapter(index) {
      this.form.chapters.splice(index, 1);
    },
    //新增课程
    onAddMaterial(index) {
      const chapters = this.form.chapters[index].reses || [];

      this.$bus.emit("open-course-material-dialog", {
        confirm: (data) => {
          //更改表单数据
          data.forEach((item) => {
            const { id, title, type } = item;
            chapters.push({
              uuid: uuid(),
              id: "",
              res_id: id,
              title,
              type,
            });
          });
        },
      });
    },
    //删除课程
    onDeleteMaterial(index, cndex) {
      const chapters = this.form.chapters[index].reses;

      chapters.splice(cndex, 1);
    },
  },
  components: {
    TypeIcon,
    PopoverInputEdit,
    Draggable,
  },
};
</script>

<style lang="scss" scoped>
.chapter-course {
  .chapter-list {
    margin-top: 20px;
    //拖拽时的样式
    &.draggable .chapter-item {
      .chapter-item-body {
        height: 0;
        overflow: hidden;
      }
    }
    .mirror-item {
      height: 58px !important;
    }
    //章节列表
    .chapter-item {
      border: 1px solid #dcdfe6;
      & + .chapter-item {
        margin-top: 20px;
      }

      .chapter-item-head {
        background-color: #f1f1f1;
        padding: 10px 15px;
        display: flex;
        align-items: center;
        .left {
          min-width: 0;
          flex-grow: 1;
          display: flex;
          align-items: center;
          .title {
            min-width: 0;
            font-size: 16px;
            line-height: 36px;
            @include nowrap();
          }
        }
        .right {
          flex-shrink: 0;
          white-space: nowrap;
          margin-left: 20px;
          button + button {
            margin-left: 15px;
          }
          .sort {
            cursor: move;
          }
        }
      }
      .chapter-item-body {
        .material-item {
          padding: 10px 15px;
          display: flex;
          align-items: center;
          font-size: 16px;
          line-height: 36px;
          & + .material-item {
            border-top: 1px solid #dcdfe6;
          }
          .serial {
            flex-shrink: 0;
            margin-right: 10px;
          }
          .title {
            margin-left: 10px;
            min-width: 0;
            flex-grow: 1;
            font-size: inherit;
            height: 36px;
            font-weight: normal;
            color: #333;
            @include nowrap();
          }
          .sort {
            padding: 5px;
            cursor: move;
          }
        }
      }
    }
  }
}
</style>
