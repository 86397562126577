<!--
 * @Author: mulingyuer
 * @Date: 2021-09-07 14:20:13
 * @LastEditTime: 2021-09-07 16:08:15
 * @LastEditors: mulingyuer
 * @Description: 基础信息
 * @FilePath: \saas-admin-vue\src\modules\online-classroom\components\course-manage\add-edit\BasicInfo.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="modular basic-info">
    <h2 class="modular-title">基本信息</h2>
    <div class="modular-content">
      <el-form-item label="课程来源：" prop="source_type">
        <div class="flex-align-center">
          <el-select v-model="form.source_type">
            <el-option
              v-for="ca in sourceOptions"
              :key="ca.id"
              :label="ca.name"
              :value="ca.id"
            />
          </el-select>
        </div>
      </el-form-item>
      <el-form-item label="课程名称：" prop="name">
        <el-input v-model="form.name" placeholder="请输入课程名称"></el-input>
      </el-form-item>
      <el-form-item label="课程封面：" prop="cover">
        <el-input v-show="false" v-model="form.cover"></el-input>

        <ImageItem
          v-if="form.cover"
          v-model="form.cover"
          width="240px"
          height="120px"
        />
        <ImageItem
          v-else
          v-model="defaultCover"
          @input="form.cover = $event"
          width="240px"
          height="120px"
        />

        <div class="info">
          <i class="el-icon-info"></i>建议尺寸为400px*400px
        </div>
      </el-form-item>
      <el-form-item label="课程类型：" prop="type">
        <el-select v-model="form.type" placeholder="请选择课程类型">
          <el-option
            v-for="op in typeArr"
            :key="op.id"
            :label="op.name"
            :value="op.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="课程分类：" prop="category_id">
        <div class="flex-align-center">
          <!--        <el-cascader v-model="form.category_id" :options="categoryArr" :props="categoryProps"-->
          <!--          placeholder="请选择课程分类"></el-cascader>-->
          <el-select v-model="form.category_id">
            <el-option
              v-for="ca in categoryArr"
              :key="ca.id"
              :label="ca.name"
              :value="ca.id"
            />
          </el-select>
          <el-button
            style="margin-left: 10px"
            size="small"
            type="primary"
            @click="onAddCategory"
            >添加分类</el-button
          >
        </div>
      </el-form-item>
      <el-form-item
        v-if="!isAlone"
        label="简介类型："
        prop="description_type"
        required
      >
        <el-radio-group v-model="form.description_type">
          <el-radio :label="0">纯文本</el-radio>
          <el-radio :label="1">富文本</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-if="!isAlone"
        label="介绍："
        prop="description"
        class="description"
      >
        <SuperTinymce v-if="form.description_type" v-model="form.description" />

        <el-input
          v-else
          v-model="form.description"
          type="textarea"
          :rows="10"
          :maxlength="3000"
          show-word-limit
          placeholder="请输入简介信息"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="关联老师："
        prop="teacher.id"
        required
        :rules="{
          required: true,
          message: '请选择关联老师',
          trigger: 'change',
        }"
      >
        <el-button @click="selectTeacher">{{
          (form.teacher && form.teacher.name) || '选择老师'
        }}</el-button>
      </el-form-item>
      <el-form-item label="是否完结：" prop="is_finished" required>
        <el-radio-group v-model="form.is_finished">
          <el-radio :label="0">否</el-radio>
          <el-radio :label="1">是</el-radio>
        </el-radio-group>
      </el-form-item>
    </div>
    <!-- 选择老师弹窗 -->
    <TeacherDialog ref="TeacherDialog"></TeacherDialog>
    <!-- 新增&编辑分类 -->
    <AddEditCatDialog
      v-model="openAddEditCatDialog"
      :editData="{}"
      :fixPid="''"
      :mode="'create'"
      :complete="getList"
    />
  </div>
</template>

<script>
import ImageItem from '@/modules/common/components/ImageItem.vue'
import SuperTinymce from '@/modules/common/components/SuperTinymce.vue'
import AddEditCatDialog from '../../course-category-manage/AddEditCatDialog.vue'
import TeacherDialog from '@/modules/online-classroom/components/course-manage/add-edit/TeacherDialog.vue'
export default {
  props: {
    //表单数据
    form: {
      type: Object,
      required: true,
    },
    //分类选项数据
    categoryArr: {
      type: Array,
      required: true,
    },
    //是否独立课程
    isAlone: {
      type: Boolean,
      required: true,
    },
    //获取分类列表
    //完成后的回调
    getList: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      //分类级联配置
      categoryProps: {
        value: 'id',
        label: 'name',
        children: 'children',
        emitPath: false,
      },
      // 来源
      sourceOptions: [
        { name: '自建', id: 0 },
        { name: '得到', id: 2 },
      ],
      //课程类型选项数据
      typeArr: [
        { name: '独立课程', id: 0 },
        { name: '章节课程', id: 1 },
      ],
      //
      defaultCover: require('@/modules/online-classroom/assets/images/class_cover.png'),
      openAddEditCatDialog: false,
    }
  },
  components: {
    ImageItem,
    SuperTinymce,
    AddEditCatDialog,
    TeacherDialog,
  },
  methods: {
    // 关联老师
    selectTeacher(id) {
      // 打开弹窗
      this.$refs.TeacherDialog.openDialog({
        confirm: (data) => {
          console.log(data)
          if (!this.form.teacher) {
            this.form.teacher = {
              id: '',
              name: '',
            }
          }
          this.form.college_teacher_id = data[0].id
          this.form.teacher.id = data[0].id
          this.form.teacher.name = data[0].name
          console.log(this.form)
        },
      })
    },
    cc(e) {
      console.log(e)
    },
    onAddCategory() {
      this.openAddEditCatDialog = true
    },
  },
}
</script>

<style lang="scss" scoped>
.basic-info {
  .description {
    max-width: 800px;
  }
}
</style>
